
import { mapState, mapGetters } from 'vuex'
import versionHistory from '~/static/version-history.json'
export default {
    layout: 'blank',
    middleware: 'noAuth',
    data: () => ({
        versionHistory,
        username: '',
        password: '',
        showPassword: false,
        otp: null,
        verifyOTPLoading: false,
    }),
    async fetch() {
        const locale = this.$cookie.get('locale')
        await this.$store.dispatch('master/translationStrings', locale)
    },
    computed: {
        ...mapState({
            loading: (state) => state.auth.loading,
            googleAuth: (state) => state.auth.googleAuth,
        }),
        ...mapGetters({
            storeTl: 'master/storeTl',
        }),
    },
    watch: {
        googleAuth(value) {
            if (value) {
                // set timeout since DOM is rendered late
                setTimeout(() => {
                    const element =
                        document.getElementsByClassName('otp-field-box--0')[0]
                    element.focus()
                }, 500)
            }
        },
    },
    mounted() {
        this.$store.dispatch('auth/verifyGoogle', {})
    },
    methods: {
        async submit(e) {
            if (this.username && this.password) {
                const formData = {
                    username: this.username,
                    password: this.password,
                }
                await this.$store.dispatch('auth/login', formData)
            } else {
                //
            }
        },
        async verifyOTP() {
            this.verifyOTPLoading = true
            const response = await this.$store.dispatch('auth/verifyGoogle', {
                opt_code: this.otp,
            })
            if (!response) {
                const element =
                    document.getElementsByClassName('otp-field-box--0')[0]
                element.focus()
            }
            this.otp = null
            this.verifyOTPLoading = false
        },
    },
}
