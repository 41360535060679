// import axios from 'axios'
export const state = () => ({
	loading: false,
	languageStrings: {},
	newCodes: [], // ? this is used for stopping making new request of addLanguageStrings multiple times, check global.js you will see
	translationString: {},
	loadingStringsForTranslation: false,


	// cancel token
	stringCancelToken: {}
})

export const actions = {
	async affiliateLanguageStrings({ commit }, params) {
		commit('setLoading', true)
		try {
			await this.$axios.get('affiliates/language-strings', { params: { limit: 2000, ...params } }).then((response) => {
				commit('setLanguageString', { data: [...response.data] })
			})
			commit('setLoading', false)
			return true;
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
	newCode({ commit }, code) {
		commit('newCode', code)
	},
}

export const mutations = {
	setLoading: (state, v) => {
		state.loading = v
	},
	setLanguageString: (state, v) => {
		state.languageStrings = v
	},
	setTranslationStrings: (state, v) => {
		state.translationString = v
	},
	loadingStringsForTranslation: (state, v) => {
		state.loadingStringsForTranslation = v
	},
	setStringCancelToken: (state, data) => {
		const oldData = { ...state.stringCancelToken }
		state.stringCancelToken = {
			...oldData, ...data
		}
	}
}

export const getters = {
	localeLanguage: state => language => {
		if (!language) {
			return {}
		}
		if (!state.languageStrings?.data?.length) return {}
		const translation = state.languageStrings.data.map((data) => {
			return {
				code: data.code,
				text: data.text[language]
			}
		})
		return translation;
	},
	storeTl: state => code => {
		if (state.translationString[code]) return state.translationString[code]
		return code
	}
}
