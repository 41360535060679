// State
export const state = () => ({
	loading: false,
	rollingSettings: [],
	preRollingSettings: [],
	rollingConfig: {},
	claims: [],
	reports: [],
	rewardResetThreshold: [],

	// cancel token
	playerRewardCancelToken: {}
});
// Actions
export const actions = {
	async rollingSettings({ commit, state }, params) {
		commit('setPlayerRewardCancelToken', { rollingSettings: this.$axios.CancelToken.source() })
		try {
			commit('loading', true)
			return await this.$axios
				.get(`/players-reward/rolling-settings`, {
					params,
					cancelToken: state.playerRewardCancelToken.rollingSettings.token
				})
				.then((response) => {
					commit('rollingSettings', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateRollingSetting({ commit }, params) {
		try {
			const id = params.id;
			delete params.id;
			commit('loading', true)
			return await this.$axios
				.put(`/players-reward/rolling-settings/item/${id}`, params)
				.then((response) => {
					commit('loading', false)
					return true;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
	async deleteRollingSetting({ commit, rootGetters }, id) {
		try {
			commit('loading', true)
			return await this.$axios
				.delete(`/players-reward/rolling-settings/item/${id}`)
				.then((response) => {
					commit('loading', false)
					return true;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
	async createRollingSetting({ commit }, params) {
		try {
			commit('loading', true)
			return await this.$axios
				.post(`/players-reward/rolling-settings/item`, params)
				.then((response) => {
					commit('loading', false)
					return true;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
	async createAndUpdateRollingSetting({ commit }, params) {
		try {
			commit('loading', true)
			return await this.$axios
				.post(`/players-reward/rolling-settings`, params)
				.then((response) => {
					commit('loading', false)
					return response.data;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
	async claims({ commit, state }, data) {
		commit('setPlayerRewardCancelToken', { claims: this.$axios.CancelToken.source() })
		const params = { ...data }
		if (params.sort.includes('created_at')) {
			delete params.sort
		}
		try {
			commit('loading', true)
			return await this.$axios
				.get(`/players-reward/claimed-rewards`, {
					params,
					cancelToken: state.playerRewardCancelToken.claims.token
				})
				.then((response) => {
					commit('claims', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async reports({ commit, state }, data) {
		commit('setPlayerRewardCancelToken', { reports: this.$axios.CancelToken.source() })
		const params = { ...data }
		if (params.sort.includes('created_at')) {
			delete params.sort
		}
		try {
			commit('loading', true)
			return await this.$axios
				.get(`/players-reward/rewards-report`, {
					params,
					cancelToken: state.playerRewardCancelToken.reports.token
				})
				.then((response) => {
					commit('reports', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async rewardResetThreshold({ commit, state }, params) {
		commit('setPlayerRewardCancelToken', { rewardResetThreshold: this.$axios.CancelToken.source() })
		try {
			commit('loading', true)
			return await this.$axios
				.get(`/players-reward/reward-reset-threshold`, {
					params,
					cancelToken: state.playerRewardCancelToken.rewardResetThreshold.token
				})
				.then((response) => {
					commit('rewardResetThreshold', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async updateRewardReset({ commit }, params) {
		try {
			commit('loading', true)
			const response = await this.$axios
				.put(`/players-reward/reward-reset-threshold/${params.currency}`, { amount: params.amount })
				.then((response) => {
					commit('loading', false)
					return response.data;
				})
			return response;
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
	async applyRollingSettings({ commit }, id) {
		try {
			commit('loading', true)
			return await this.$axios
				.put(`/players-reward/rolling-settings/apply/${id}`)
				.then((response) => {
					commit('loading', false)
					return response.data;
				})
		} catch (error) {
			commit('loading', false)
			return false;
		}
	},
}

// Mutations
export const mutations = {
	loading(state, loading) {
		state.loading = loading;
	},
	rollingSettings(state, data) {
		state.rollingSettings = data?.rolling_settings;
		state.preRollingSettings = data?.pre_rolling_settings;
		state.rollingConfig = data?.rolling_setting_config
	},
	claims(state, data) {
		state.claims = data;
	},
	reports(state, data) {
		state.reports = data;
	},
	rewardResetThreshold(state, data) {
		state.rewardResetThreshold = data;
	},
	setPlayerRewardCancelToken: (state, data) => {
		const oldData = { ...state.playerRewardCancelToken }
		state.playerRewardCancelToken = {
			...oldData, ...data
		}
	}
}

export const getters = {
	sortRollingSetting: (state) => {
		// Create a new sorted array without mutating the state
		const sortedArr = state.rollingSettings?.slice().sort((a, b) => {
			return parseInt(a.rollover_start_range, 10) - parseInt(b.rollover_start_range, 10);
		});

		// Return the sorted array
		return sortedArr;
	},
	sortPreRollingSetting: (state) => {
		// Create a new sorted array without mutating the state
		const sortedArr = state.preRollingSettings?.slice().sort((a, b) => {
			return parseInt(a.rollover_start_range, 10) - parseInt(b.rollover_start_range, 10);
		});

		// Return the sorted array
		return sortedArr;
	},
};