
import versionHistory from '~/static/version-history.json'
export default {
    props: {
        displayName: {
            type: Boolean,
            default: true,
        },
    },
    data: () => ({
        versionHistory,
    }),
    methods: {
        usernameChar() {
            if (this.$cookie.get('user') && this.$cookie.get('user').name) {
                return this.$cookie.get('user').name.toString().charAt(0) || 'C'
            }
        },
        logout() {
            if (confirm(this.tl('areYouSure'))) {
                this.$store.dispatch('auth/logout')
            }
        },
    },
}
