
import versionHistory from '~/static/version-history.json'

export default {
    data() {
        return {
            dialog: false,
            latestUpdate: {},
        }
    },
    mounted() {
        this.checkVersionUpdate()
    },
    methods: {
        checkVersionUpdate() {
            const seenUpdate = localStorage.getItem('lastSeenUpdate')
            const latest = versionHistory[0] // Assuming the first item is the latest update.

            if (!seenUpdate || seenUpdate !== latest.id) {
                this.latestUpdate = {
                    id: latest.id,
                    title: latest.title,
                    changes: latest.changes,
                    date: latest.date,
                }
                this.dialog = true
            }
        },
        acknowledgeUpdate() {
            const latest = versionHistory[0]
            localStorage.setItem('lastSeenUpdate', latest.id)
            this.dialog = false
        },
    },
}
